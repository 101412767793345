import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const NoMatch = (props) => {
    const location = useLocation();
    const {t: translate} = useTranslation()

    if (props.min)
        return (
            <div
                className={
                    "h-100 w-100 d-flex justify-content-center align-items-center bg-light"
                }
            >
                <h3 className={"text-dark"}>
                    {translate('errors.start404')} <code>{location.pathname}</code> {translate('errors.end404')}
                </h3>
                <Link to={"/"} className={"text-warning text-decoration-none"}>
                    {translate('errors.backToMain')}
                </Link>
            </div>
        );
    return (
        <div className={"h-100 w-100 d-flex flex-column justify-content-center align-items-center bg-light"}>
            <h3 className={"text-dark"}>
                {translate('errors.start404')} <code>{location.pathname}</code> {translate('errors.end404')}
            </h3>
            <Link to={`${props.to}`} className={"text-primary text-decoration-none"}>
                {translate('errors.backToMain')}
            </Link>
        </div>
    );
};

export default NoMatch;
