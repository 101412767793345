import React, { useState } from 'react'
import { Toast } from 'react-bootstrap'
import { onMessageListener } from '../../../firebase-messaging-sw'
import './styles.scss'

const PopupToast = () => {
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState({ title: '', body: '' })

  onMessageListener()
    .then((payload) => {
      setShow(true)
      setNotification({
        title: payload.data.title,
        body: payload.data.body,
      })
    })
    .catch((err) => console.log('failed: ', err))

  return (
    <Toast
      onClose={() => setShow(false)}
      show={show}
      delay={3000}
      autohide
      animation
      className='popup-toast'
    >
      <Toast.Header className='popup-toast__header'>
        <strong className='mr-auto'>{notification.title}</strong>
      </Toast.Header>
      <Toast.Body>{notification.body}</Toast.Body>
    </Toast>
  )
}

export default PopupToast
