import * as firebase from 'firebase/app'
import {getMessaging, getToken, onMessage, isSupported} from 'firebase/messaging'

const firebaseConfig = {
    apiKey: "AIzaSyC1dl-cu4rrVcTcIDlLgYjS6100iTjq06Y",
    authDomain: "smurno-7030a.firebaseapp.com",
    projectId: "smurno-7030a",
    storageBucket: "smurno-7030a.appspot.com",
    messagingSenderId: "981123498715",
    appId: "1:981123498715:web:2f38f5f6aec53f5f2e02fe"
};

const app = firebase.initializeApp(firebaseConfig)
let canUseFCM = await isSupported()

const messaging = canUseFCM ? getMessaging(app) : null;

export function requestPermission(onMessage = () => {
}) {
    if (canUseFCM && messaging) Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            getToken(messaging, {
                vapidKey: process.env.REACT_APP_PUSH_NOTIFICATIONS_VAPID_KEY,
            }).then((currentToken) => {
                if (currentToken) {
                    const oldToken = JSON.parse(
                        localStorage.getItem('gis-river-fcm-token-old')
                    )

                    if (!oldToken) {
                        localStorage.setItem(
                            'gis-river-fcm-token-old',
                            JSON.stringify(currentToken)
                        )
                    }

                    localStorage.setItem(
                        'gis-river-fcm-token',
                        JSON.stringify(currentToken)
                    )
                } else {
                    console.log('Can not get token')
                }
            })
        } else {
            console.log('Do not have permission')
        }
    })
}

requestPermission()

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })
