//prod
// const server = 'https://back.kedr.dev.yourtar.ru/';

//stage
const server = process.env.REACT_APP_SERVER_URL ?? 'https://back.kedr.dev.yourtar.ru';
// const socket = 'http://127.0.0.1:8000/.well-known/mercure?topic=';

//dev
// const server = 'http://localhost:8000/';
// const socket = 'http://127.0.0.1:8000/';

export const serverUrl = server + '/api';
export const socketUrl = server + '/.well-known/mercure?topic=';
export const version = '1.1.0';
