const defaultState = { user: null, token: null }

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return Object.assign({}, action.payload)
    case 'LOGOUT':
      return defaultState
    case 'UPDATE':
      return {
        ...state,
        user: action.payload,
      }
    default:
      return state
  }
}

export default userReducer
